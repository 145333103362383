.container {
  min-height: 100vh;
}

.model {
  height: 50%;
  margin-left: 1rem;
}

.form-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}



