.Document-page {

  }

.document-treeView {
    background-color: rgb(247, 247, 248);
}

.document-table {
    flex: 4 1 0;
}

