.form-control {
  border: none !important;
}

.form-control label,
.form-control input,
.form-control select,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control select,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid rgba(224, 224, 224, 1);
  background: transparent;
  padding: 0.5rem 0.5rem;
}

.form-control input:focus,
.form-control select:focus,
.form-control textarea:focus {
  outline: none;
  background: white;
  border-color: #cb3694;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid select,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}

.traceTableOfModelStatus {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding: 100rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.form-control input {
  background-image: linear-gradient(0deg, rgb(255, 255, 255) 2px, white 0px),
    linear-gradient(0deg, white 1px, transparent 0px);
  background-size: 0 100%, 100% 100%;
  background: no-repeat bottom, 50% calc(100% - 1px);
}

.multiple_select {
  z-index: 9999999999;
  position: relative;
  overflow: visible;
}

.form-metricTable {
  font-weight: bold;
  /* margin: 3rem 0.8rem 0 0.8rem; */
}

.ql-editor {
  height: 150px;
  resize: vertical;
  font-size: 16px;
}

.quill-wrapper .ql-toolbar {
  transition: all 0.3s ease;
}

/* Toolbar is disabled and faded, input is still active */
.quill-wrapper.toolbar-disabled .ql-toolbar {
  opacity: 0.5; /* Fades the toolbar */
  pointer-events: none; /* Disable interactions */
}

/* Both toolbar and input are disabled and faded */
.quill-wrapper.disabled .ql-toolbar {
  opacity: 0.5;
  pointer-events: none;
}

.quill-wrapper.disabled .ql-container {
  background-color: #f0f0f0;
  opacity: 0.5; /* Fades the input */
  pointer-events: none; /* Disable text input */
}
