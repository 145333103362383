.formGroup {
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 10px;
}

.leftCol {
  text-align: right;
}

.rightCol {
  text-align: left;
}

.label {
  font-weight: bold;
  margin-top: 1rem;
}

.row {
  display: flex;
  width: auto;
}

.submitButton {
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}

/* Table */
.traceTableOfModelStatus {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding: 100rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.traceTableOfModelStatus td {
  border: 1px solid rgb(135, 11, 11);
  font-size: small;
  font-weight: normal;
  
}

.traceTableOfModelStatus tr:hover {
  background-color: #f1f1f1;

}

.tableOfModelStatus {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: auto !important;
  text-align: center;
  table-layout: fixed;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.tableOfModelStatus td {
  border: 1px solid rgb(135, 11, 11);
  font-size: small;
  font-weight: normal;
  width: auto;
}

.tableOfModelStatus td:hover {
  background-color: #f1f1f1;
}

/* file uploader */
.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  text-align: center !important;
  margin: 0;
}
.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}
.files {
  display: flex;
  justify-content: center;
  margin-bottom: -2rem;
}
.uploadBorder {
  border-radius: 0.375rem;
}

.fileOperations {
  text-align:center !important;
}

.fileOperations th {
  border-radius: 0;
  padding: 0 !important;
  text-align:center !important;
}

.fileOperations th div {
  font-size: 0.9rem;
  padding: 0 !important;
}

.fileOperations th div input {
  text-align:center;
  border: none;
}

.fileName input {
  text-align:left !important;
}




