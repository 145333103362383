.task-link {
    color: var(--bs-link-color) !important;
    -webkit-text-decoration: none !important;
    text-decoration: underline !important;
    font-size: 0.875rem !important;
    border: none !important;
}

.task-link a {
    border: none !important;

}