.container {
  /*padding: 10px;
  flex: 1;
   overflow: scroll; */
}

.button {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.search {
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.input {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}
/* treeview styling */
.treeview {
  font-size: xx-large;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}
summary {
  outline: none;
  font-size: 1rem;
}

summary::-webkit-details-marker {
  display: none;
}

.pdate {
  font-size: 2rem;
  margin-bottom: -0.1rem;
  text-indent: 1.5rem;
  cursor: pointer;
}

