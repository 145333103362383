.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  padding: 3rem;
  margin-top: 10rem;
}
