.parent-init {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0rem;
  grid-row-gap: 0;
  margin: 2rem 2rem 0 8rem;
}

.div1-init {
  grid-area: 1 / 1 / 2 / 2;
}
.div2-init {
  grid-area: 2 / 1 / 3 / 2;
}
.div3-init {
  grid-area: 2 / 2 / 3 / 3;
  padding: 1rem;
}
.div3-init .form-control {
  margin-top: 1rem;
}
.div4-init {
  grid-area: 3 / 1 / 4 / 3;
  display: flex;
  gap: 10rem;
  margin-top: 3rem;
  justify-content: center;
}
.editButton {
  padding: 5px !important;
    font-size: 15px !important;
    margin: 1rem !important;
    font-weight: normal !important;
}
.modal-input {
  display: flex !important;
  flex-wrap: wrap;
  gap: 1rem;
}
.modal-input > div {
  flex:30%; /* or - flex: 0 50% - or - flex-basis: 50% - */
  /*demo*/
  box-shadow: 0 0 0 1px black;
  margin-bottom: 10px;
}
.modal-button { 
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 10rem;
}