.task_config-layout {
  display: flex;
  margin-top: 2rem;
}

.task_config-container {
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 5px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 100%;
}

.task_config-1 {
  width: 40%;
  margin-left: 1rem;
}

.task_config-2 {
  width: 60%;
  margin-left: 1rem;
}

.task_config-3 {
  width: 90%;
  margin-left: 1rem;
  margin-top: 1rem;
}

.task_config-button {
  display: flex;
  gap: 3rem;
  justify-content: center;
  margin-top: 1rem;
}

.task_config-option {
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 5px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 30%;
}
.task_config-3 .form-control {
  margin-top: 1rem;
}
