.container {

}


.issue {
  height: 50%;
  margin-left: 1rem;
}

.error-message {
  text-align: center;
  margin-top: 10rem;
}
.container {
  min-height: 100vh;
}

.issue {
  height: 50%;
  margin-left: 1rem;
}

.form-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.parent-issue {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 2rem 7rem 2rem 7rem;
}

.div1-issue {
  grid-area: 1 / 1 / 2 / 3;
}
.div2-issue {
  grid-area: 2 / 1 / 3 / 2;
}
.div3-issue {
  grid-area: 2 / 2 / 3 / 3;
}
.div4-issue {
  grid-area: 3 / 1 / 4 / 2;
}
.div5-issue {
  grid-area: 3 / 2 / 4 / 3;
}
.div6-issue {
  grid-area: 4 / 1 / 5 / 2;
}
.div7-issue {
  grid-area: 4 / 2 / 5 / 3;
}
.div8-issue {
  grid-area: 5 / 1 / 6 / 2;
}
.div9-issue {
  grid-area: 5 / 2 / 6 / 3;
}
.div10-issue {
  grid-area: 6 / 1 / 7 / 2;
}
.div11-issue {
  grid-area: 6 / 2 / 7 / 3;
}
.div12-issue {
  grid-area: 7 / 1 / 8 / 2;
}
.div13-issue {
  grid-area: 8 / 1 / 8 / 3;
}
.div14-issue {
  grid-area: 9 / 1 / 9 / 3;
}
.div15-issue {
  grid-area: 10 / 1 / 10 / 3;
}
.div16-issue {
  grid-area: 11 / 1 / 11 / 3;
}
.div17-issue {
  grid-area: 12 / 1 / 12 / 3;
}
.div18-issue {
  grid-area: 13 / 1 / 13 / 3;
}
.div19-issue {
  grid-area: 14 / 1 / 14 / 3;
  display: flex;
  justify-content: center;
}
.div20-issue {
  grid-area: 15 / 1 / 15 / 3;
  display: flex;
  justify-content: center;
}
.div21-issue {
  grid-area: 16 / 1 / 16 / 3;
  display: flex;
  justify-content: center;
}
.div22-file {
  grid-area: 17 / 1 / 17 / 3;
  display: flex;
  justify-content: center;
}
.div23-submit {
  grid-area: 18 / 1 / 18 / 3;
  display: flex;
  justify-content: center;
}
.div24-sequence {
  grid-area: 19 / 1 / 19 / 3;
  display: flex;
  justify-content: center;
}
