.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: black;
}

.main-navigation__title {
  color: black;
}

.main-navigation__title a {
  text-decoration: none;
  color: black;
  font-size: 100%;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}


  .main-navigation__title {
    display: block;
  }


.main-header {
  display: flex;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  height: 4rem;
  padding: 4.2rem 0 3.5rem 2.5rem;
  z-index: 5;
}
