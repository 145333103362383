.Issue-form {
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  /*max-width: 40rem;*/
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.new-Issue__form {
  margin-top: 4rem;
  max-width: 50rem;
}

.Issue-item__image {
  width: 100%;
  height: 20rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
}
.Issue-item__radio {
  width: 100%;
  height: 5rem;
  margin-bottom: 3rem;
  padding: 0.5rem;
}
.issueInput {
  padding: 1rem;
}

.input-tag {
  display: flex;
  align-items: baseline;
}
.input-tag__textField {
  margin-left: 1rem !important;
}

.btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.btn_position {
  display: flex;
  justify-content: center;
}

.metric-score {
  text-align: center;
}

