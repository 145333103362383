.parent-issues {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /*grid-template-rows: repeat(16, 1fr);*/
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 2rem 7rem 2rem 7rem;
}

.div1-issues {
  grid-area: 1 / 1 / 2 / 2;
}
.div2-issues {
  grid-area: 1 / 2 / 2 / 3;
}
.div3-issues {
  grid-area: 2 / 1 / 3 / 2;
}
.div4-issues {
  grid-area: 2 / 2 / 3 / 3;
}
.div5-issues {
  grid-area: 3 / 1 / 4 / 2;
}
.div6-issues {
  grid-area: 3 / 2 / 4 / 3;
}
.div7-issues {
  grid-area: 4 / 1 / 5 / 2;
}
.div8-issues {
  grid-area: 4 / 2 / 5 / 3;
}
.div9-issues {
  grid-area: 5 / 1 / 6 / 2;
}
.div10-issues {
  grid-area: 5 / 2 / 7 / 3;
}
.div11-issues {
  grid-area: 7 / 1 / 8 / 3;
}
.div12-issues {
  grid-area: 8 / 1 / 9 / 3;
}
.div13-issues {
  grid-area: 9 / 1 / 10 / 3;
}
.div14-issues {
  grid-area: 10 / 1 / 11 / 3;
}
.div15-issues {
  grid-area: 11 / 1 / 12 / 3;
}
.div16-issues {
  grid-area: 12 / 1 / 13 / 3;
}
.div17-issues {
  grid-area: 13 / 1 / 14 / 3;
}
.div18-issues {
  grid-area: 14 / 1 / 15 / 3;
}
.div19-issues {
  grid-area: 15 / 1 / 16 / 3;
}
.div-file {
  grid-area: 16 / 1 / 17 / 3;
  display: flex;
  justify-content: center;
}
.div-submit {
  grid-area: 17 / 1 / 18 / 3;
  display: flex;
  justify-content: center;
}

.div-sequence {
  grid-area: 18 / 1 / 19 / 3;
  display: flex;
  justify-content: center;
}

