.container {
    min-height: 100vh;
  }
  
  .document {
    height: 50%;
    margin-left: 1rem;
  }
  
  .form-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .parent-document {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 2rem 7rem 7rem 7rem;
  }
  
  .div1-document { grid-area: 1 / 1 / 2 / 2; }
  .div2-document { grid-area: 1 / 2 / 2 / 3; }
  .div3-document { grid-area: 2 / 1 / 3 / 3; }
  .div4-document { grid-area: 3 / 1 / 4 / 3; }
  .div5-document { grid-area: 4 / 1 / 5 / 3; }
  .div6-document {
    grid-area: 5 / 1 / 6 / 3;
    display: flex;
    justify-content: center;
  }
  .div7-document {
    grid-area: 6 / 1 / 7 / 3;
    display: flex;
    justify-content: center;
  }
  
  