@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  box-sizing: border-box;

  font-family: "Roboto", sans-serif !important;
}

html {
  overflow-y: hidden;
}

body {
  /* background-color: #e2e4e5; */
  margin: 0;
  height: 100%;
  width: 100%;
}

header,
footer {
  background-color: white;
  color: #fff;
}

footer {
  position: fixed !important;
  bottom: 0;
  z-index: 999;
}

/* react-split */
.gutter {
  background-color: #b4b4b5;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
  cursor: col-resize;
}

.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
  cursor: row-resize;
}

/*.split {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}*/

input:focus {
  border-color: #cb3694 !important;
}

/* .tox-edit-area::before {
  border-color: #CB3694 !important;
} */

.ant-select-focused .ant-select-selector {
  border-color: #cb3694 !important;
  /* Change to your desired color */
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  border-color: rgb(203, 54, 148) !important;
  /* Change to your desired hover color */
}

.ant-select-disabled .ant-select-selector {
  background-color: #f1f1f1 !important;
  /* Change to your desired color */
}

.Mui-focused {
  border-color: #cb3694 !important;
}

input:disabled {
  -webkit-text-fill-color: rgb(54, 54, 54) !important;
  color: #000000;
  background: #f1f1f1 !important;
}

select:disabled {
  /* -webkit-text-fill-color: black !important;
  color: #000000; */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #f1f1f1 !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #cb3694 !important;
}

.form-control select:focus {
  border-color: #cb3694 !important;
}

.MuiInputBase-inputMultiline {
  border-color: #cb3694 !important;
}

.MuiDataGrid-cell.MuiDataGrid-cell--editing {
  border: 1px solid #cb3694 !important;
}

.MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within {
  border: 1px solid #cb3694;
  outline-color: #cb3694 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #cb3694 !important;
}

.MuiMenu-list {
  background-color: white !important;
}

.MuiMenuItem-root {
  color: black !important;
}

.MuiAutocomplete-popper {
  background-color: white !important;
}

.ant-select-selection-item {
  background: white !important;
  color: #333333 !important;
}

.tox-edit-area::before {
  border: 1px solid #cb3694 !important;
}

.MuiAvatar-root,
.MuiButtonBase-root,
.MuiTreeItem-root,
.MuiPaper-root,
.MuiDataGrid-root,
.nav-links li,
.MuiButtonBase-root,
.ant-popover-inner,
.ant-btn {
  border-radius: 5px !important;
}

.ant-select-dropdown .ant-select-item-option-active {
  background-color: rgb(226, 227, 238) !important;
}

.ant-select-dropdown .ant-select-item-option:hover {
  background-color: rgb(226, 227, 238) !important;
}

.ant-select-dropdown .ant-select-item-option-selected {
  background-color: rgb(226, 227, 238) !important;
}
