.option-config {
  display: flex;
  width: auto;
  gap: 4rem;
  margin: 2rem 0 0 0;
}

.selection {
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 5px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 100%;
  position: relative;
}

.new-option {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.form-control-config {
  width: 85%;
}

.new-option button {
  margin: 0.5rem !important;
  padding: 0.5rem !important;
  font-size: 14px !important;
}
