.main-content {
  margin-top: 4rem;
  /*margin-bottom: 5rem;*/
  width: 100%;
}
.right {
  flex: 2;

  overflow-y: auto;
}

.plain-page {
  overflow-y: auto;
}

.split {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.left {
  overflow-y: auto;
  position: relative;
  padding-top: 1vh;
}




