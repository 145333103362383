 .opm-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0
  }

 .opm-button {
    background-color: black !important;
  }

  
