.topNav {
  background-color: #003366;
  color: #fff;
  height: 64px;
  padding: 5px 14px;
}

.logo {
  cursor: pointer;
  width: 128px;
  height: 44px;
  margin-top: 2.5px;
  border-radius: 7px;
}

.validator {
  margin-left: auto;
  margin-top: auto;
}

.date {
  margin-left: 20px;
  margin-top: auto;
}

.button {
  margin-left: 20px;
}

.system-link {
  color: #fff;
  margin-left: 20px;
}

.header-layout {
  display: grid
}