.menu-link {
  list-style: none;
  margin: 0;
  padding-top: 0;

  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.drop_down_menu li a {
  border: 1px solid transparent;
  color: #292929 !important;
  text-decoration: none !important;
  padding: 0.5rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 500 !important;
  width: 150%;
}

.drop_down_menu li a:hover,
.drop_down_menu li a:active,
.drop_down_menu li a.active {
  background: rgb(228, 225, 225);
  border-color: #292929;
  color: #292929;
}

/*button {
    cursor: pointer;
    border: 1px;
    color: #292929;
    background: transparent;
    padding: 0.5rem;
    font: inherit;
    font-weight: 500 !important;
  }*/

button:focus {
  outline: none;
}

button:hover,
button:active {
  background: rgb(228, 225, 225);
  border-color: #292929;
  color: #292929;
}

@media (min-width: 768px) {
  .menu-link {
    flex-direction: row;
  }

  .menu-link li {
    margin: 0 0.5rem;
  }

  .menu-link a {
    color: black;
    text-decoration: none;
  }

  .menu-link button {
    background: transparent;
  }

  .menu-link button:hover,
  .menu-link button:active {
    background: rgb(228, 225, 225);
    border-color: #292929;
    color: #292929;
  }
}
