.nav-links {
  list-style: none;
  margin: 0;
  padding-top: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 1px;
}
.nav-links li {
  margin-left: 0.6rem;
  padding: 0.2rem;
}
.nav-links a {
  border: transparent;
  color: #292929;
  text-decoration: none;
  /* padding: 0.5rem; */
  border-radius: 6px;
}

.nav-links a:hover {
  background: rgb(255, 145, 0);
  border-color: #292929;
  color: #292929;
  border-radius: 6px;
  border: transparent;
}

.nav-links a.active {
  color: "white";
}

.nav-links li:hover,
.nav-links li:active {
  background: rgb(255, 145, 0);
  border-color: #292929;
  color: #292929;
  border-radius: 6px;
  border: 1px transparent;
}

.nav-links button {
  cursor: pointer;
  border: 1px;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}
.nav-links button:focus {
  outline: none;
}
.nav-links button:hover,
.nav-links button:active {
  background: rgb(228, 225, 225);
  border-color: #292929;
  color: #292929;
}
@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
    text-align: center;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }
  .nav-links button {
    color: white;
    background: transparent;
  }
  .nav-links button:hover,
  .nav-links button:active {
    border-color: #292929;
    color: #292929 !important;
  }
}
