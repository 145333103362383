

h4 {
  display: flex;
  justify-content: center;
}


 .user_attribute {
    width: 50%;
  }

.user_user_group {
    width: 50%;
}

.modal-user {
  display: grid !important;
  flex-wrap: wrap;
  gap: 1rem;
}
.modal-user > div {

   flex:50%; /* or - flex: 0 50% - or - flex-basis: 50% - */
   /*demo*/
   box-shadow: 0 0 0 1px black;
   margin-bottom: 10px;
 }

